import { useEffect, useState } from "react";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Container,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
// sections
//import { LoginForm } from "../../sections/auth/login";
// utils
import { clearCookie } from "../../utils/cookie";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();
  let [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    clearCookie("access_token");
    clearCookie("refresh_token");

    let next = searchParams.get("next");

    if (!next) {
      next = localStorage.getItem("next");
    }

    if (!next) {
      setError(translate("Login.MissingNext"));
    } else {
      localStorage.setItem("next", next);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Page title="Logout">
      <RootStyle>
        <HeaderStyle>
          <Logo disabledLink={true} />
        </HeaderStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate("Login.LoggedOut")}
                </Typography>
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={loading}
              component={RouterLink}
              to={"/auth/login"}
              sx={{ mb: 3 }}
            >
              <span>{translate("Login.BackToLogin")}</span>
            </LoadingButton>
            {!!error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <AlertTitle>{translate("Login.Error")}:</AlertTitle>
                {error}
              </Alert>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
