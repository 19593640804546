import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
// hooks
import useSettings from "../hooks/useSettings";

import SvgIconStyle from "./SvgIconStyle";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const { themeMode } = useSettings();

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <SvgIconStyle
        src="/icons/satelliet_logo_small.svg"
        sx={{
          width: "100%",
          height: "100%",
          color: themeMode === "light" ? "grey.700" : "common.white",
        }}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
