import { useState, useEffect } from "react";
import axios from "../utils/axios";

const useAxios = (url, options) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      setError(null);
      setLoading(true);
      try {
        const response = await axios(url, { ...options });
        setData(response.data);
      } catch (error) {
        if (error.response === undefined) {
          setError(error.message);
        } else {
          setError(error.response.data.detail);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, options]);

  return { data, loading, error };
};

export default useAxios;
