// i18n
import "./locales/i18n";
// import packageJson from "../package.json";

import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { SettingsProvider } from "./contexts/SettingsContext";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { Provider, ErrorBoundary } from "@rollbar/react";

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.VITE_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.VITE_GIT_SHA,
      },
    },
    server: {
      branch: import.meta.env.VITE_APP_BRANCH,
    },
  },
  enabled: import.meta.env.PROD,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
    </ErrorBoundary>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
