import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useSearchParams,
  useParams,
} from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Container,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useResponsive from "../../hooks/useResponsive";
import useLocales from "../../hooks/useLocales";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
// sections
//import { LoginForm } from "../../sections/auth/login";

import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ALLOWED_HOSTS = ["spa", "template", "admin", "ritplanner", "bezoek"];

export default function Callback() {
  const { translate } = useLocales();
  let { backend } = useParams();
  let [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  let location = window.location;

  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    let code = searchParams.get("code");
    let autherror = searchParams.get("error");
    if (!!autherror) {
      setError(autherror);
    } else if (!!code) {
      axios(`/user/social/sat-azure-tenant/callback/?code=${code}`)
        .then((req) => {
          if (location.hostname === "localhost") {
            document.cookie = `access_token=${req.data.access}; max-age=${
              60 * 60
            }; secure; path=/`;
            document.cookie = `refresh_token=${req.data.refresh}; max-age=${
              60 * 60 * 24 * 365
            }; secure; path=/`;
          } else {
            document.cookie = `access_token=${req.data.access}; max-age=${
              60 * 60
            }; secure; path=/; Domain=.sap.satelliet.net`;
            document.cookie = `refresh_token=${req.data.refresh}; max-age=${
              60 * 60 * 24 * 365
            }; secure; path=/; Domain=.sap.satelliet.net`;
          }

          let next = localStorage.getItem("next");
          let path = localStorage.getItem("path");
          localStorage.removeItem("next");
          localStorage.removeItem("path");

          if (ALLOWED_HOSTS.includes(next?.toLowerCase())) {
            if (location.hostname !== "localhost") {
              if (path.startsWith("/")) {
                window.location.assign(
                  `https://${next}.${location.host}${path}`
                );
              } else {
                window.location.assign(`https://${next}.${location.host}/`);
              }
            } else {
              window.location.assign(`http://localhost:3000/`);
            }
          } else {
            setError(translate("Login.InvalidNext"));
          }
        })
        .catch((error) => {
          if (error.response === undefined) {
            setError(error.message);
          } else {
            setError(error.response.data.detail);
          }
          setLoading(false);
        });
    } else {
      setError(translate("Login.InvalidCode"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo disabledLink={true} />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {translate("Login.WelcomeBack")}
            </Typography>
            <Image
              alt="login"
              src="/images/illustrations/illustration_login.png"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate("Login.LoggingIn")}
                </Typography>
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={loading}
              component={RouterLink}
              to={"/auth/login"}
              sx={{ mb: 3 }}
            >
              <span>{translate("Login.BackToLogin")}</span>
            </LoadingButton>
            {!!error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <AlertTitle>{translate("Login.Error")}:</AlertTitle>
                {error}
              </Alert>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
