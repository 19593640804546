import axios from "axios";
// config
import { BACKEND_URL } from "../config";
// utils
import i18n from "../locales/i18n";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  async function (config) {
    config.headers["Accept-Language"] = i18n.language;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
