import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useResponsive from "../../hooks/useResponsive";
import useLocales from "../../hooks/useLocales";
import useAxios from "../../hooks/useAxios";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
// sections
//import { LoginForm } from "../../sections/auth/login";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();
  let [searchParams] = useSearchParams();
  const {
    data,
    loading,
    error: uriError,
  } = useAxios("/user/social/sat-azure-tenant/auth-uri/");
  const [error, setError] = useState(null);

  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    let next = searchParams.get("next");
    let path = searchParams.get("path");

    if (!next) {
      next = localStorage.getItem("next");
      path = "/";
    }

    if (!path) {
      path = "/";
    }

    if (!next) {
      setError(translate("Login.MissingNext"));
    } else {
      localStorage.setItem("next", next);
      localStorage.setItem("path", path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo disabledLink={true} />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {translate("Login.WelcomeBack")}
            </Typography>
            <Image
              alt="login"
              src="/images/illustrations/illustration_login.png"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate("Login.SignIn")}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {translate("Login.SelectLogin")}
                </Typography>
              </Box>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={loading || !!uriError || !!error}
              component={Link}
              href={!!data ? data.auth_uri : undefined}
              sx={{ mb: 3 }}
            >
              <span>{translate("Login.LoginMicrosoft")}</span>
            </LoadingButton>
            {!!uriError && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <AlertTitle>{translate("Login.Error")}:</AlertTitle>
                {uriError}
              </Alert>
            )}
            {!!error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                <AlertTitle>{translate("Login.Error")}:</AlertTitle>
                {error}
              </Alert>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
